import {
	Circle3QuarterLined,
	CircleCrossFilled,
	CircleTickFilled,
	GiveHeartFilled,
	GlobeFilled,
	Heartbeat2Filled,
	MinusFilled,
	PalmFilled,
	PlusFilled,
	Wallet2Filled,
	WalletFilled,
	WheeledSuitcaseFilled,
} from "@tag/tag-icons";
import { HolidayTypeModel } from "../models/HolidayTypeModel";
import { TimesheetValidationModel } from "../models/TimesheetValidationModel";
export const getHolidayIcons = (holidayType: HolidayTypeModel | undefined) => {
	switch (holidayType?.shortName) {
        case "CO":
            return <PalmFilled style={{ fill: "#C55300" }} />;

        case "CM":
            return <Heartbeat2Filled style={{ fill: "#E5173F" }} />;

        case "CPL":
            return <WalletFilled style={{ fill: "#537B36" }} />;

        case "CFS":
            return <Wallet2Filled style={{ fill: "#17294B" }} />;

        case "AN":
            return <MinusFilled style={{ fill: "#8B1E36" }} />;

        case "IP":
            return <PlusFilled style={{ fill: "#537B36" }} />;

        case "CIC":
            return <GiveHeartFilled style={{ fill: "#8601AF" }} />;

        case "D":
            return <WheeledSuitcaseFilled style={{ fill: "#8601AF" }} />;

        case "BB":
            return <PalmFilled style={{ fill: "#C55300" }} />;

        case "SN":
            return <GlobeFilled style={{ fill: "#145863" }} />;
        case "CHR":
            return <GiveHeartFilled style={{ fill: "#145863" }} />;
        default:
            return <>--</>;
    }
};

export const getValidationIcons = (timesheetValidation: TimesheetValidationModel) => {
	switch (timesheetValidation.statusLiteral) {
		case "Pending":
			return <Circle3QuarterLined style={{ fill: "#5a5c5e" }} />;
		case "Approved":
			return <CircleTickFilled style={{ fill: "#246B3C" }} />;
		case "Rejected":
			return <CircleCrossFilled style={{ fill: "#AD230A" }} />;
		default:
			return <>--</>;
	}
};
