export const baseColors = [
    "#007A7A",
    "#ECCC3C",
    "#537B36",
    "#C55300",
    "#5E4EBC",
    "#B24666",
];

// Generate a mixed palette by cycling through base colors and adjusting brightness
export const generateColorPalette = (count: number) => {
    const shades = baseColors.map((color, i) => {
        // Generate lighter versions of each base color
        const shadeCount = Math.ceil(count / baseColors.length);
        return Array.from({ length: shadeCount }, (_, j) => {
            const factor = 1 + (j * 0.5); // Adjust lightness progressively
            return adjustColorBrightness(color, factor);
        });
    });
    return shades.flat().slice(0, count); // Flatten and limit to the required count
};
export const adjustColorBrightness = (color: string, factor: number) => {
    const rgb = hexToRgb(color);
    const adjusted = rgb.map((c) => Math.min(255, Math.max(0, Math.round(c * factor))));
    return rgbToHex(adjusted[0], adjusted[1], adjusted[2]);
};

export const hexToRgb = (hex: string) => {
    const sanitizedHex = hex.replace("#", "");
    return [
        parseInt(sanitizedHex.substring(0, 2), 16),
        parseInt(sanitizedHex.substring(2, 4), 16),
        parseInt(sanitizedHex.substring(4, 6), 16),
    ];
};
export const rgbToHex = (r: number, g: number, b: number) =>
    `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
