import { P, Toggle, ToggleOption } from "@tag/tag-components-react-v4";
import { AccessLined, CircleFilled } from "@tag/tag-icons";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { useContext, useEffect, useMemo, useState } from "react";
import {
    DayChanges,
    disableToggle,
    getDayWorkedHours,
    isToday,
} from "../../../utils/TimesheetUtils";
import { getHolidayIcons } from "../../../utils/getIconsWithAccents";
import { TimesheetModel } from "../../../models/TimesheetModel";
import { TimesheetRangePicker } from "../TimesheetRangePicker.tsx/TimesheetRangePicker";
import { TimesheetContext } from "../../../contexts/TimesheetContext";

dayjs.extend(LocalizedFormat);

export type TimesheetRowProps = {
    weekData: TimesheetModel;
    updateWorkHours: (hours: number) => void;
    updateDayChanges: (changes: DayChanges) => void;
};

export const TimesheetRow = (props: TimesheetRowProps) => {
    const { profile } = useContext(TimesheetContext);
    const { weekData, updateWorkHours, updateDayChanges } = props;
    const [dayBegin, setDayBegin] = useState<string | undefined>(weekData.dayBegin ?? undefined);
    const [dayEnd, setDayEnd] = useState<string | undefined>(weekData.dayEnd ?? undefined);

    const dayOfWeek = dayjs(weekData.date).format("dddd");
    const matchingSchedule = profile?.workLocationSchedule?.find(
        (schedule) => schedule.dayOfWeek === dayOfWeek,
    );
    const [workType, setWorkType] = useState<number | undefined>(
        weekData.id !== null && weekData.workType
            ? weekData.workType?.id
            : matchingSchedule?.workType.id,
    );
    // Update work type based on schedule
    useMemo(() => {
        if (weekData.dayBegin) setDayBegin(weekData.dayBegin);
        if (weekData.dayEnd) setDayEnd(weekData.dayEnd);
    }, [weekData.dayBegin, weekData.dayEnd]);

    // Update worked hours and day changes
    useEffect(() => {
        if (dayBegin && dayEnd) {
            const workedHours = getDayWorkedHours(dayBegin, dayEnd);
            updateWorkHours(workedHours);
        } else if (weekData.holidayType) {
            updateWorkHours(profile?.workingHours ?? 0);
        } else updateWorkHours(0);

        if (
            dayBegin !== weekData.dayBegin ||
            dayEnd !== weekData.dayEnd ||
            (workType !== weekData?.workType?.id && workType !== matchingSchedule?.workType?.id)
        ) {
            updateDayChanges({
                dayBegin: dayBegin,
                dayEnd: dayEnd,
                workType: workType,
            });
        }
    }, [
        dayBegin,
        dayEnd,
        workType,
        matchingSchedule,
        profile,
        weekData.holidayType,
        weekData.dayBegin,
        weekData.dayEnd,
    ]);
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                flexWrap: "wrap",
            }}
        >
            {/* Pointer for the current day */}
            <div style={{ width: "25px" }}>
                {isToday(weekData.date) ? (
                    <AccessLined accent="teal" size="large" />
                ) : (
                    <div style={{ width: "25px" }}></div>
                )}
            </div>
            {/* Display the full date as yyyy */}
            <div>{dayjs(weekData.date).format("YYYY-MM-DD")}</div>
            <div style={{ width: "50px" }}>{dayjs(weekData.date).format("dddd")}</div>

            <div>
                <CircleFilled
                    style={{
                        fill: `${
                            (weekData.dayBegin && weekData.dayEnd && !weekData.isValid) ||
                            weekData.holidayType
                                ? "#007A7A"
                                : "#B1B8BF"
                        }`,
                    }}
                />
            </div>
            <div>
                <TimesheetRangePicker
                    weekData={weekData}
                    dayBegin={dayBegin}
                    setDayBegin={setDayBegin}
                    dayEnd={dayEnd}
                    setDayEnd={setDayEnd}
                />
            </div>
            <div style={{ textAlign: "center" }}>
                {(() => {
                    if (dayEnd && dayBegin) {
                        return (
                            <P>
                                {getDayWorkedHours(dayBegin, dayEnd)} / {profile?.workingHours}
                            </P>
                        );
                    } else if (weekData.holidayType) {
                        return (
                            <P>
                                {profile?.workingHours} / {profile?.workingHours}
                            </P>
                        );
                    } else {
                        return <P>0 / {profile?.workingHours}</P>;
                    }
                })()}
            </div>
            <div style={{ display: "flex", width: "50px" }}>
                {weekData.holidayType ? getHolidayIcons(weekData.holidayType) : "-"}
                {weekData.holidayType ? weekData.holidayType.shortName : "-"}
            </div>
            <div>
                <Toggle
                    value={workType}
                    onChange={(e) => setWorkType(e.value)}
                    disabled={disableToggle(profile, weekData)}
                >
                    <ToggleOption value={1}>Office</ToggleOption>
                    <ToggleOption value={2}>Home Office</ToggleOption>
                </Toggle>
            </div>
        </div>
    );
};
