import {
    DataList,
    design,
    H5,
    InfoCard,
    P,
    Spinner,
} from '@tag/tag-components-react-v4';
import { CircleInfoFilled, MegaphoneFilled } from '@tag/tag-icons';
import { useState } from 'react';
import { DayOffModal } from '../components/DayOffModal/DayOffModal';
import { getHolidayIcons } from '../utils/getIconsWithAccents';
import { DeleteDayOffModal } from '../components/DayOffModal/DeleteDayOffModal';
import { isEditOrDeleteDisabled, LeaveData, mapHolidayToLeaveData } from '../utils/leavesPageUtils';
import { useGetHolidays } from '../api/useGetHolidays';
import dayjs from 'dayjs';

export const LeavesPage = () => {
    const { data: holidays, isLoading, error } = useGetHolidays();

    if (isLoading) return <Spinner />;
    if (error) return <P>Couldn't retrieve holidays</P>;

    const leavesData: LeaveData[] = holidays ? mapHolidayToLeaveData(holidays) : [];


    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                height: "inherit",
                margin: "1%",
            }}
        >
            <InfoCard
                heading="Automatic sync"
                icon={<MegaphoneFilled />}
                style={{ marginBottom: "1%", border: "1px solid #f5dada" }}
            >
                <P>
                    All leaves are now imported from People XD automatically. You can still view
                    them here and signal to HR any issues.
                </P>
                <P style={{ fontWeight: "bold" }}>The data is updated every 24 hours.</P>
            </InfoCard>
            <DataList
                renderIcon={(dataItem) =>
                    getHolidayIcons(dataItem.model.type ? dataItem.model.type : undefined)
                }
                primaryField="typeName"
                primaryFieldProps={{
                    style: { display: "flex", maxWidth: "100%", flexWrap: "nowrap" },
                }}
                renderRightElements={(dataItem) => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <H5
                            style={{
                                fontWeight: "normal",
                                color: design.surfaces.s050.color,
                            }}
                        >
                            {dayjs(dataItem.begin).format("DD/MM/YYYY")}
                        </H5>
                        <H5
                            style={{
                                fontWeight: "normal",
                                color: design.surfaces.s050.color,
                            }}
                        >
                            {dayjs(dataItem.end).format("DD/MM/YYYY")}
                        </H5>
                    </div>
                )}
                data={leavesData}
            />
        </div>
    );
};
