import React from "react";
import { StatsModel } from "../../models/StatsModel";
import ReactECharts from "echarts-for-react";
import { generateColorPalette } from "../../utils/chartsUtils";
export type DepartmentChartProps = {
    statistics: StatsModel | undefined;
};
export const DepartmentChart = (props: DepartmentChartProps) => {
    const { statistics } = props;
    const departmentData = statistics?.departmentsStats;
    const employeesOnDepartments = departmentData?.map((dep) => ({
        value: dep.count,
        name: dep.department.name,
    }));

    const colorPalette = generateColorPalette(employeesOnDepartments?.length || 0);
    const option = {
        title: {
            text: "Departments",
            subtext: "Romania",
            left: "center",
        },
        tooltip: {
            trigger: "item",
        },
        legend: {
            orient: "vertical",
            left: "left",
            top: "top",
        },
        color: colorPalette,
        series: [
            {
                name: "Employees",
                type: "pie",
                radius: "50%",
                left: "25%",
                data: employeesOnDepartments,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                },
            },
        ],
    };
    return (
        <ReactECharts
            option={option}
            style={{
                // height: 400,
                width: "100%",
                // paddingTop: "15px",
                // paddingLeft: "20px",
                // marginLeft: "1%",
                // width: "10rem",
                height: "50rem",
            }}
            opts={{ renderer: "svg" }}
        />
    );
};
