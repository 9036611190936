import {
    Alert,
    Badge,
    Card,
    H4,
    Label,
    Pager,
    Searchbox,
    SimpleButton,
    Spinner,
    SquareButton,
    Tooltip,
    useToasts,
} from "@tag/tag-components-react-v4";
import React, { useContext, useState } from "react";
import { TimesheetNav } from "../commons/TimesheetNav/TimesheetNav";
import dayjs from "dayjs";
import { getMonday } from "../../utils/dateUtils";
import { CircleTickFilled, HourglassFullFilled, UndoFilled } from "@tag/tag-icons";
import { useValidationsStatsForAdmin } from "../../api/AdminApi/useAdminGetStats";
import { TimesheetContext } from "../../contexts/TimesheetContext";
import { AdminStatsTable } from "./AdminStatsTable";
import { useAdminUpdateTimeSheet } from "../../api/AdminApi/useAdminUpdateTimeSheet";
import { dayStat, UpdateTimesheetAdminModel } from "../../models/UpdateTimesheetAdminModel";
import { TOAST_CONTAINER_ID } from "../../constants";
import { useQueryClient } from "@tanstack/react-query";
import { useDebounce } from "../../hooks/useDebounce";
import { ErrorCard } from "../commons/ErrorCard";

export const AdminEmployeeStats = () => {
    const { accessToken } = useContext(TimesheetContext);
    const { toast } = useToasts(TOAST_CONTAINER_ID);
    const queryClient = useQueryClient();
    const [startDate, setStartDate] = useState<Date>(
        getMonday(dayjs().toDate()), // Monday
    );
    const [endDate, setEndDate] = useState<Date>(
        dayjs(startDate).startOf("week").add(5, "day").toDate(), // Friday
    );

    const [selectedEmployee, setSelectedEmployee] = useState<number>(-1);
    const [dayStats, setDayStats] = useState<dayStat[]>([]);
    const [searchCriteria, setSearchCriteria] = useState<string>("");
    const debouncedSearchCriteria = useDebounce(searchCriteria, 250);
    const [page, setPage] = useState<number>(1);
    const pageSize = 13;
    const {
        data: statsForAdminData,
        isLoading: isStatsLoading,
        error: statsError,
    } = useValidationsStatsForAdmin(
        accessToken,
        startDate,
        endDate,
        page,
        pageSize,
        debouncedSearchCriteria,
    );

    const handlePrevOrNextWeek = (direction: "prev" | "next") => {
        const offset = direction === "prev" ? -7 : 7;
        setStartDate(dayjs(startDate).add(offset, "day").toDate());
        setEndDate(dayjs(endDate).add(offset, "day").toDate());
    };
    const handleCurrentWeek = () => {
        setStartDate(dayjs().startOf("week").add(1, "day").toDate()); // Monday
        setEndDate(dayjs().startOf("week").add(5, "day").toDate()); // Friday
    };
    const handleSearch = (e: any) => {
        setSearchCriteria(e);
        setPage(1);
    };

    const { mutate: updateTimesheetAdmin, isPending: isFillWeekLoading } =
        useAdminUpdateTimeSheet();

    const handleFillWeek = () => {
        dayStats.forEach((day) => {
            const timesheetDay: UpdateTimesheetAdminModel = {
                id: day.id,
                date: day.date,
                dayBegin: day.dayBegin,
                dayEnd: day.dayEnd,
                workTypeId: day.workTypeId,
            };
            updateTimesheetAdmin(
                { id: day.profileId, day: timesheetDay, token: accessToken },
                {
                    onSuccess: () => {
                        toast(`Week filled successfully`, { toastType: "success" });
                        setDayStats([]);
                        setSelectedEmployee(-1);
                        queryClient.invalidateQueries();
                    },
                    onError: (error) => toast(error.message, { toastType: "error" }),
                },
            );
        });
    };

    return (
        <div>
            <Card accent="teal" style={{ textAlign: "center", padding: "1%", margin: "1%" }}>
                <H4> Stats </H4>
            </Card>
            <div>
                <div
                    style={{
                        margin: "1%",
                        display: "flex",
                        gap: "16px",
                        alignItems: "center",
                        alignContent: "center",
                        justifyContent: "flex-start",
                    }}
                >
                    <TimesheetNav
                        startDate={startDate}
                        endDate={endDate}
                        handlePrevOrNextWeek={handlePrevOrNextWeek}
                        handleCurrentWeek={handleCurrentWeek}
                    />
                    <div
                        style={{
                            display: "flex",
                            gap: "16px",
                            alignItems: "center",
                        }}
                    >
                        <Searchbox
                            placeholder="Search by name"
                            style={{ width: "300px" }}
                            onSearch={handleSearch}
                            clearButton
                        />
                        <Tooltip
                            tooltipFor={
                                <SquareButton
                                    icon={<UndoFilled />}
                                    onClick={() => {
                                        setSelectedEmployee(-1);
                                    }}
                                />
                            }
                        >
                            Clear selected
                        </Tooltip>
                        <Badge
                            style={{
                                width: "35px",
                                height: "35px",
                                backgroundColor: `${selectedEmployee ? "teal" : "sgrey"}`,
                            }}
                        >
                            <Label style={{ color: "white" }}>{selectedEmployee > 0 ? 1 : 0}</Label>
                        </Badge>
                        <SimpleButton
                            onClick={handleFillWeek}
                            accent="teal"
                            disabled={isFillWeekLoading || selectedEmployee === -1}
                            endIcon={
                                isFillWeekLoading ? <HourglassFullFilled /> : <CircleTickFilled />
                            }
                        >
                            Fill Week
                        </SimpleButton>
                    </div>
                </div>
            </div>
            <div>
                {(() => {
                    if (isStatsLoading)
                        return <Spinner style={{ margin: "7%" }}>Loading...</Spinner>;
                    else if (statsForAdminData && statsForAdminData.items.length) {
                        return (
                            <div>
                                <AdminStatsTable
                                    selectedEmployee={selectedEmployee}
                                    setSelectedEmployee={setSelectedEmployee}
                                    stats={statsForAdminData?.items}
                                    startDate={startDate}
                                    endDate={endDate}
                                    dayStats={dayStats}
                                    setDayStats={setDayStats}
                                />
                                <Pager
                                    style={{ margin: "1%" }}
                                    page={page}
                                    pageCount={Math.ceil(statsForAdminData.totalCount / pageSize)}
                                    accent="teal"
                                    showFirstLast
                                    onPageRequest={(e) => setPage(e.page)}
                                />
                            </div>
                        );
                    } else
                        return (
                            <Alert style={{ margin: "1%" }}>
                                No data found for <strong>{searchCriteria}</strong>
                            </Alert>
                        );
                    if (statsError) return <ErrorCard error={statsError} />;
                })()}
            </div>
        </div>
    );
};
