import { ManagerLeaves } from "../components/Managers/ManagerLeaves";
import { ManagerReportGenerator } from "../components/Managers/ManagerReportGenerator";
import { ManagerBadApplesView } from "../components/Managers/ManagerBadApplesView";
import { ManagerTeamStats } from "../components/Managers/ManagerTeamStats";

export const ManagersPage = () => {
    return (
        <div>
            <ManagerReportGenerator />
            <ManagerBadApplesView />
            {/* <ManagerLeaves /> */}
            <ManagerTeamStats />
        </div>
    );
};
