import React from "react";
import { StatsModel } from "../../models/StatsModel";
import ReactECharts from "echarts-for-react";
import { generateColorPalette } from "../../utils/chartsUtils";

export type HolidaysChartProps = {
    statistics: StatsModel | undefined;
};

export const HolidaysChart = (props: HolidaysChartProps) => {
    const { statistics } = props;
    const holidaysData = statistics?.holidayStats;
    const employeesOnHoliday = holidaysData?.map((holiday) => ({
        value: holiday.count,
        name: holiday.type.name,
    }));
    const colorPalette = generateColorPalette(employeesOnHoliday?.length || 0);

    const option = {
        title: {
            text: "Holidays",
            subtext: "Today",
            left: "center",
        },
        tooltip: {
            trigger: "item",
        },
        legend: {
            orient: "vertical",
            left: "right",
            top: "top",
        },
        color: colorPalette,
        series: [
            {
                name: "Employees",
                type: "pie",
                right: "10%",
                radius: "50%",
                // avoidLabelOverlap: true,
                data: employeesOnHoliday,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                },
            },
        ],
    };
    return (
        <ReactECharts
            option={option}
            style={{
                // marginRight: "1%",
                width: "100%",
                height: "50rem",
                // height: 400,
                // width: "60%",
                // paddingTop: "15px",
                // paddingLeft: "20px",
            }}
            opts={{ renderer: "svg" }}
        />
    );
};
