
import { StatisticsComponents } from "../components/Statistics/StatisticsComponents";

export const StatisticsPage = () => {
    return (
        <div>
            <StatisticsComponents />
        </div>
    );
};
