import dayjs from "dayjs";
import { baseURL } from "../../constants"
import { useMutation } from "@tanstack/react-query";

const exportIncompleteTimesheetReport  = async (token: string) => {
 const url = `${baseURL}/api/Admin/ExportBadApples`;
 const monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
 const lastMonday = dayjs().startOf("week").subtract(1, "day").subtract(1, "week");
 const lastSunday = lastMonday.add(6, "day");
 const currentDate = dayjs();
 
 const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/octet-stream",
      "Content-Disposition": "attachment; filename=raport.xlsx",
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error("Failed to export report");
  }

  const blob = await response.blob();
  const objectURL = URL.createObjectURL(blob);
  const tempLink = document.createElement("a");
  tempLink.href = objectURL;
   tempLink.setAttribute(
      "download",
      `report/for/incomplete-timesheet/${lastMonday.date()}-${monthNames[lastMonday.month()]}/${lastSunday.date()}-${monthNames[lastSunday.month()]}/and ${monthNames[currentDate.month()]}/.xlsx`
    );
	tempLink.click();
	return blob;

}

export const useExportIncompleteTimesheet = () => {
	return useMutation({
		mutationFn: ({token} : {token: string}) => exportIncompleteTimesheetReport(token),
	})
}