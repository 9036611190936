import React, { useEffect, useMemo } from "react";
import {
    Autocomplete,
    Badge,
    Card,
    H4,
    Label,
    Modal,
    SimpleButton,
    Spinner,
    SquareButton,
    Tooltip,
    useToasts,
} from "@tag/tag-components-react-v4";
import { useContext, useState } from "react";
import { TimesheetNav } from "../commons/TimesheetNav/TimesheetNav";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

import { TimesheetContext } from "../../contexts/TimesheetContext";
import {
    CircleCrossFilled,
    CircleTickFilled,
    HourglassFullFilled,
    SaveDiskFilled,
    UndoFilled,
} from "@tag/tag-icons";
import { ValidationManyModel } from "../../models/ValidationManyModel";
import { getMonday, makeTimes } from "../../utils/dateUtils";
import { getStatsForManagerWithUiKey, holidayOrNot } from "../../utils/managerUtils";
import { ManagerTeamStatsTable } from "./ManagerTeamStatsTable";
import { useValidationStatsForManager } from "../../api/ManagersApi/useValidationStatsForManager";
import { ErrorCard } from "../commons/ErrorCard";
import { useManagerValidateAll } from "../../api/ManagersApi/useManagerValidateAll";
import { useManagerValidateMany } from "../../api/ManagersApi/useManagerValidateMany";
import { TOAST_CONTAINER_ID } from "../../constants";
import { useQueryClient } from "@tanstack/react-query";
import { useManagerUpdateTimesheet } from "../../api/ManagersApi/useManagerUpdateTimesheet";
import { dayStat } from "../../models/UpdateTimesheetAdminModel";
import { AdminStatsTable } from "../Admin/AdminStatsTable";

dayjs.extend(advancedFormat);

export type ManagerTeamStastProps = {};

export const ManagerTeamStats = (props: ManagerTeamStastProps) => {
    const { accessToken } = useContext(TimesheetContext);
    const { toast } = useToasts(TOAST_CONTAINER_ID);
    const queryClient = useQueryClient();

    const [startDate, setStartDate] = useState<Date>(
        getMonday(dayjs().toDate()), // Monday
    );
    const [endDate, setEndDate] = useState<Date>(
        dayjs(startDate).startOf("week").add(5, "day").toDate(), // Friday
    );
    const [dayStats, setDayStats] = useState<dayStat[]>([]);
    const [selectedEmployee, setSelectedEmployee] = useState<number>(-1);

    const [comboValue, setComboValue] = useState<string>("");
    const [selected, setSelected] = useState<ValidationManyModel[]>([]);

    const [dayState, setDayState] = useState<any>({
        id: null,
        date: null,
        dayBegin: null,
        dayEnd: null,
        workTypeId: null,
        profileId: 0,
    });
    useEffect(() => {
        if (selected.length === 1) {
            const { date, dayBegin, dayEnd, idPontaj, ownedBy } = selected[0];
            if (dayBegin !== null && dayEnd !== null) {
                setDayState((prev: any) => ({
                    ...prev,
                    // ...dayState,
                    profileId: ownedBy,
                    date: new Date(date).toDateString(),
                    dayBegin: dayBegin,
                    dayEnd: dayEnd,
                    id: idPontaj,
                    workTypeId: null,
                }));
            } else {
                setDayState((prev: any) => ({
                    ...prev,
                    // ...dayState,
                    profileId: ownedBy,
                    date: new Date(date).toDateString(),
                    dayBegin: null,
                    dayEnd: null,
                    id: null,
                    workTypeId: null,
                }));
            }
        } else {
            setDayState((prev: any) => ({
                ...prev,
                // ...dayState,
                profileId: 0,
                date: "",
                dayBegin: undefined,
                dayEnd: undefined,
                id: null,
                workTypeId: null,
            }));
        }
    }, [selected]);

    const handlePrevOrNextWeek = (direction: "prev" | "next") => {
        const offset = direction === "prev" ? -7 : 7;
        setStartDate(dayjs(startDate).add(offset, "day").toDate());
        setEndDate(dayjs(endDate).add(offset, "day").toDate());
    };
    const handleCurrentWeek = () => {
        setStartDate(dayjs().startOf("week").add(1, "day").toDate()); // Monday
        setEndDate(dayjs().startOf("week").add(5, "day").toDate()); // Friday
    };
    const {
        data: validationStatsForManager,
        isLoading: isValidationStatsLoading,
        error: validationStatsError,
    } = useValidationStatsForManager(accessToken, startDate, endDate);
    const validationStatsWithUiKey = validationStatsForManager
        ? getStatsForManagerWithUiKey(validationStatsForManager)
        : [];

    const handleFillWeek = () => {
        //TODO: Use new endpoint
    };

    return (
        <div>
            <Card accent="teal" style={{ textAlign: "center", padding: "1%", margin: "1%" }}>
                <H4> Team Stats </H4>
            </Card>
            <div>
                <div style={{ margin: "1%", display: "flex", gap: "16px", alignItems: "center" }}>
                    <TimesheetNav
                        startDate={startDate}
                        endDate={endDate}
                        handlePrevOrNextWeek={handlePrevOrNextWeek}
                        handleCurrentWeek={handleCurrentWeek}
                    />
                    <Tooltip
                        tooltipFor={
                            <SquareButton
                                icon={<UndoFilled />}
                                onClick={() => {
                                    setSelected([]);
                                }}
                            />
                        }
                    >
                        Clear selected
                    </Tooltip>
                    <Badge
                        style={{
                            width: "35px",
                            height: "35px",
                            backgroundColor: `${selected.length > 0 ? "teal" : "sgrey"}`,
                        }}
                    >
                        <Label style={{ color: "white" }}>{selected.length}</Label>
                    </Badge>

                    <SimpleButton
                        endIcon={<CircleTickFilled />}
                        onClick={() => {
                            handleFillWeek();
                        }}
                        accent="teal"
                        disabled={selected.length === 0}
                    >
                        Fill Week
                    </SimpleButton>
                </div>
                {(() => {
                    if (isValidationStatsLoading) {
                        return <Spinner style={{ margin: "7%" }}>Loading...</Spinner>;
                    } else if (validationStatsError) {
                        return <ErrorCard error={validationStatsError} />;
                    } else {
                        return (
                            <ManagerTeamStatsTable
                                selected={selected}
                                setSelected={setSelected}
                                startDate={startDate}
                                endDate={endDate}
                                validationStatsWithUiKey={validationStatsWithUiKey}
                            />
                            // <AdminStatsTable
                            //     selectedEmployee={selectedEmployee}
                            //     setSelectedEmployee={setSelectedEmployee}
                            //     stats={statsForAdminData?.items}
                            //     startDate={startDate}
                            //     endDate={endDate}
                            //     dayStats={dayStats}
                            //     setDayStats={setDayStats}
                            // />
                        );
                    }
                })()}
            </div>
        </div>
    );
};
